import React from "react";
import { Profile, Timeline } from "./";
import Image from "./bg.svg";

const styles = {
  bg: {
    backgroundImage: `url(${Image})`,
    position: "absolute",
    zIndex: "-1",
    opacity: 0.1,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: "hidden",
  },
};

function Home() {
  return (
    <div>
      <div id="bg" style={styles.bg}></div>
      <Profile />
      <Timeline />
    </div>
  );
}

export default Home;
