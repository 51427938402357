import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Work, School, Star } from "@mui/icons-material";
import "./Timeline.scss";
import { Container, SvgIcon } from "@mui/material";

export default function Timeline() {
  return (
    <>
      <Container id={"profile"} className="container" maxWidth="lg">
        <VerticalTimeline className="timeline">
          <VerticalTimelineElement
            id="work"
            className="vertical-timeline-element"
            date="2022 - 2023"
            contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid rgb(33, 150, 243)" }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                className="blue-bg-link"
                target="_blank"
                rel="noopener noreferrer"
                href="https://land.tech"
              >
                LandTech
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2019 - 2021"
            iconStyle={{ background: "rgb(97, 218, 251)", color: "#fff" }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hodgebank.co.uk/"
              >
                Hodge Bank, Cardiff
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="2021"
            iconStyle={{ background: "rgb(97, 218, 251)", color: "#fff" }}
            icon={<ReactIcon />}
          >
            <h3 className="vertical-timeline-element-title">React Sandbox</h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://narcis.dev"
              >
                narcis.dev
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="2021"
            iconStyle={{ background: "#f06529", color: "#fff" }}
            icon={<HTML5 />}
          >
            <h3 className="vertical-timeline-element-title">
              Static Portfolio
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://minhajul.design"
              >
                minhajul.design
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element"
            date="2020"
            iconStyle={{ background: "rgb(33, 117, 155)", color: "#fff" }}
            icon={
              <WordPress
                color="white"
                style={{
                  stroke: "none",
                  fillRule: "evenodd",
                  fill: "#fff",
                  fillOpacity: "1",
                }}
              />
            }
          >
            <h3 className="vertical-timeline-element-title">Wordpress</h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://silviabottero.com"
              >
                silviabottero.com
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2018 - 2019"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">
              Software Engineer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.alcumus.com/"
              >
                Alcumus, Nantgarw
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2016 - 2018"
            iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
            icon={<Work />}
          >
            <h3 className="vertical-timeline-element-title">
              Junior Front-End Developer
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hardingevans.com/"
              >
                Harding Evans Solicitors, Newport
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            id="education"
            className="vertical-timeline-element--education"
            date="2013 - 2016"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<School />}
          >
            <h3 className="vertical-timeline-element-title">
              BSc (Hons) Software Engineering
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.cardiffmet.ac.uk/"
              >
                CardiffMet
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2008-2013"
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#fff" }}
            icon={<School />}
          >
            <h3 className="vertical-timeline-element-title">
              Accountancy and Law
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.itcgtoscanelli.edu.it/"
              >
                I.T.C.G. Paolo Toscanelli
              </a>
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
            icon={<Star />}
          />
        </VerticalTimeline>
      </Container>
    </>
  );
}

function WordPress(props) {
  return (
    <SvgIcon {...props}>
      <path d="M 12 1.679688 C 14.691406 1.679688 17.140625 2.710938 18.980469 4.402344 C 18.078125 4.378906 17.179688 4.914062 17.140625 6.136719 C 17.066406 8.617188 20.105469 9.144531 18.46875 14.128906 L 17.113281 18.25 L 13.425781 7.382812 C 13.367188 7.214844 13.433594 7.121094 13.640625 7.121094 L 14.683594 7.121094 C 14.738281 7.121094 14.78125 7.074219 14.78125 7.019531 L 14.78125 6.398438 C 14.78125 6.34375 14.738281 6.292969 14.683594 6.296875 C 12.710938 6.382812 10.746094 6.382812 8.792969 6.296875 C 8.738281 6.292969 8.695312 6.34375 8.695312 6.398438 L 8.695312 7.019531 C 8.695312 7.074219 8.742188 7.121094 8.792969 7.121094 L 9.84375 7.121094 C 10.078125 7.121094 10.140625 7.199219 10.195312 7.347656 L 11.710938 11.492188 L 9.433594 18.378906 L 5.683594 7.339844 C 5.636719 7.203125 5.695312 7.121094 5.828125 7.121094 L 7.023438 7.121094 C 7.078125 7.121094 7.121094 7.074219 7.121094 7.019531 L 7.121094 6.398438 C 7.121094 6.34375 7.078125 6.292969 7.023438 6.296875 C 5.769531 6.363281 4.511719 6.378906 3.339844 6.390625 C 5.179688 3.554688 8.371094 1.679688 12 1.679688 Z M 21.074219 7.085938 C 21.867188 8.546875 22.316406 10.21875 22.316406 12 C 22.316406 15.902344 20.148438 19.300781 16.953125 21.050781 L 20.167969 11.9375 C 20.667969 10.527344 21.128906 8.621094 21.074219 7.085938 Z M 15.453125 21.722656 C 14.375 22.109375 13.210938 22.316406 12 22.316406 C 10.957031 22.316406 9.949219 22.160156 9 21.875 L 12.175781 12.765625 Z M 7.585938 21.328125 C 4.09375 19.671875 1.679688 16.117188 1.679688 12 C 1.679688 10.472656 2.011719 9.023438 2.609375 7.71875 Z M 12 0.710938 C 18.230469 0.710938 23.285156 5.765625 23.285156 12 C 23.285156 18.230469 18.230469 23.285156 12 23.285156 C 5.765625 23.285156 0.714844 18.230469 0.714844 12 C 0.714844 5.765625 5.765625 0.710938 12 0.710938 Z M 12 0 C 18.625 0 24 5.375 24 12 C 24 18.625 18.625 24 12 24 C 5.375 24 0 18.625 0 12 C 0 5.375 5.375 0 12 0 Z M 12 0 " />
    </SvgIcon>
  );
}
function HTML5(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <path
          style={{ fill: "white" }}
          d="M 3.328125 21.5625 L 1.40625 0 L 22.546875 0 L 20.625 21.5625 L 11.953125 24 "
        />
        <path
          style={{ fill: "white" }}
          d="M 12 22.125 L 18.984375 20.203125 L 20.625 1.734375 L 12 1.734375 "
        />
        <path
          style={{ fill: "#f06529" }}
          d="M 12 9.75 L 8.484375 9.75 L 8.25 7.03125 L 12 7.03125 L 12 4.40625 L 5.34375 4.40625 L 5.390625 5.109375 L 6.046875 12.421875 L 12 12.421875 Z M 12 16.640625 L 11.953125 16.640625 L 9 15.84375 L 8.8125 13.734375 L 6.1875 13.734375 L 6.515625 17.90625 L 11.953125 19.40625 L 12 19.40625 Z M 12 16.640625 "
        />
        <path
          style={{ fill: "#f06529" }}
          d="M 11.953125 9.75 L 11.953125 12.421875 L 15.234375 12.421875 L 14.90625 15.84375 L 11.953125 16.640625 L 11.953125 19.40625 L 17.390625 17.90625 L 17.4375 17.4375 L 18.046875 10.453125 L 18.140625 9.75 Z M 11.953125 4.40625 L 11.953125 7.03125 L 18.375 7.03125 L 18.421875 6.46875 L 18.5625 5.109375 L 18.609375 4.40625 Z M 11.953125 4.40625 "
        />
      </g>
    </SvgIcon>
  );
}
function ReactIcon(props) {
  return (
    <SvgIcon {...props}>
      <g xmlns="http://www.w3.org/2000/svg" id="surface1">
        <path
          style={{
            fill: "white",
            stroke: "none",
            fillRule: "nonzero",
            fillOpacity: "1",
          }}
          d="M 13.988281 11.984375 C 13.988281 13.09375 13.089844 13.992188 11.980469 13.992188 C 10.871094 13.992188 9.972656 13.09375 9.972656 11.984375 C 9.972656 10.875 10.871094 9.976562 11.980469 9.976562 C 13.089844 9.976562 13.988281 10.875 13.988281 11.984375 Z M 13.988281 11.984375 "
        />
        <path
          style={{
            stroke: "white",
            fill: "none",
            strokeWidth: "144.7456",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: "1",
            strokeMiterlimit: "10",
          }}
          d="M 1806.55485 1191.607585 C 2212.985514 1191.607585 2589.964681 1249.921549 2874.466146 1348.289551 C 3217.281576 1466.09554 3428.154297 1645.160645 3428.154297 1807.14388 C 3428.154297 1975.606445 3204.911947 2165.863118 2836.768229 2287.792318 C 2558.157064 2380.27002 2192.369466 2427.981445 1806.55485 2427.981445 C 1411.315755 2427.981445 1036.692708 2383.215169 755.136393 2286.614258 C 399.362305 2165.274089 184.955404 1972.661296 184.955404 1807.14388 C 184.955404 1646.338704 385.814616 1469.04069 723.917806 1351.234701 C 1009.597331 1251.688639 1396.590007 1191.607585 1806.55485 1191.607585 Z M 1806.55485 1191.607585 "
          transform="matrix(0.00663167,0,0,0.00663167,0,0)"
        />
        <path
          style={{
            stroke: "white",
            fill: "none",
            strokeWidth: "144.7456",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: "1",
            strokeMiterlimit: "10",
          }}
          d="M 1271.126628 1501.437337 C 1473.75293 1149.197428 1712.899089 851.737305 1940.264648 653.823242 C 2213.574544 415.855143 2473.925781 322.788411 2614.114909 404.074544 C 2760.194336 488.305827 2813.207031 776.341471 2734.866048 1156.265788 C 2675.963053 1443.712402 2534.595866 1784.760742 2341.983073 2119.329753 C 2144.65804 2461.556152 1918.47054 2763.139486 1694.63916 2959.286458 C 1411.315755 3206.679036 1136.827799 3296.211589 993.693522 3213.747396 C 854.682454 3133.639323 801.080729 2870.342936 868.230143 2518.692057 C 924.187988 2221.231934 1066.144206 1856.622396 1271.126628 1501.437337 Z M 1271.126628 1501.437337 "
          transform="matrix(0.00663167,0,0,0.00663167,0,0)"
        />
        <path
          style={{
            stroke: "white",
            fill: "none",
            strokeWidth: "144.7456",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: "1",
            strokeMiterlimit: "10",
          }}
          d="M 1271.715658 2119.918783 C 1067.911296 1768.267904 929.489258 1412.493815 871.764323 1117.389811 C 802.258789 761.026693 851.148275 489.483887 991.337402 408.197754 C 1137.416829 323.377441 1413.671875 421.745443 1704.063639 679.15153 C 1923.18278 873.531413 2148.19222 1166.868327 2341.394043 1500.259277 C 2539.308105 1842.485677 2688.332682 2189.424316 2746.057617 2480.994141 C 2819.097331 2850.315918 2759.605306 3132.461263 2616.471029 3214.925456 C 2477.459961 3295.622559 2222.999023 3210.213216 1951.456217 2976.957357 C 1722.323568 2779.632324 1477.287109 2474.514811 1271.715658 2119.918783 Z M 1271.715658 2119.918783 "
          transform="matrix(0.00663167,0,0,0.00663167,0,0)"
        />
      </g>
    </SvgIcon>
  );
}
