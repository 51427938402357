import React from "react";
import "./ProfilePicture.scss";
import Gravatar from "react-gravatar";
export default function ProfilePicture({ image }) {
  return (
    <div className="profile-picture">
      <Gravatar md5="d5efe0562d325f1be66d8b34baae8e16" size={500} />
    </div>
  );
}
