import React, { useEffect, useRef } from "react";
import { Card, CardContent, Container } from "@mui/material";
import Typed from "typed.js";
import ProfilePicture from "./ProfilePicture";

export default function Profile() {
  const animatedTitle = useRef(null);

  useEffect(() => {
    const typed = new Typed(animatedTitle.current, {
      strings: [
        "Human ",
        "Designer ",
        "Developer ",
        "Gamer ",
        "AH-64 Apache Helicopter ",
      ],
      typeSpeed: 60,
      backSpeed: 30,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <Container id={"profile"} className="container" maxWidth="lg">
      <ProfilePicture image={"/images/profile.jpeg"} />
      <div>
        <h1 className="font">Narcis Cristea</h1>
        <h2 className="title font">
          <span ref={animatedTitle}></span>
        </h2>
        <div className="profile-description">
          <Card>
            <CardContent>
              <p>I tap on a keyboard and code comes out.</p>
            </CardContent>
          </Card>
        </div>
      </div>
    </Container>
  );
}
