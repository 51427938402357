import React from "react";
import { BottomNavigation } from "@mui/material";
import { BottomNavigationAction } from "@mui/material";
import { AccountBox, Work, School } from "@mui/icons-material";

export default function CustomBottomNavigation() {
  const style = {
    position: "fixed",
    width: "-webkit-fill-available",
    bottom: 0,
  };
  return (
    <BottomNavigation showLabels style={style}>
      <BottomNavigationAction
        href={"#profile"}
        label="Profile"
        icon={<AccountBox />}
      />
      <BottomNavigationAction href={"#work"} label="Work" icon={<Work />} />
      <BottomNavigationAction
        href={"#education"}
        label="Education"
        icon={<School />}
      />
    </BottomNavigation>
  );
}
